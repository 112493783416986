import React from 'react'
const Productdelivery = (props)=>{
    return(
       <div>
        <div className="flex j pro_d_wrap">
            <div className="pro_d_h">배송정보</div>
            <div className="pro_d_b">
                <span>택배발송</span>
                <p>택배사 : 롯데택배 1588-2121 또는 기타 택배</p>
                <p>배송지역 : 전국 (제주 및 도서산간지역은 3,000원 추가 운임이 발생하며 일부 지역은 +@ 추가 비용이 발생 됩니다.)</p>
                <p>배송기간 : 영업일 기준 평균 1~3일 소요되며, 택배사 사정에 따라 달라질 수 있습니다. (주말/공휴일 배송기간 제외)</p>
                <p>무료배송 : 총 결제 금액이 배송비를 제외하고 66,000원(vat포함) 이상인 경우 무료배송 처리됩니다.</p>
                <p>주문 후 상품의 품절ㆍ 단종 및 고객 변심 등 부분 취소로 인하여 최종 결제 금액이 66,000원 이하가 되는 경우, 2,700원(롯데택배 출고기준/VAT포함)의
기본 배송비가 발생될 수 있습니다.</p>
<p>유료배송 : 롯데택배 출고 기준 2,700원(vat포함)</p>
<p>무료/유료 배송의 경우 : 직배송, 타 택배 이용시 유료배송 비용은 변경됩니다.</p>
<p>부피 및 무게가 10KG 이상, 총 합의 길이가 1.2M 이상 또는 배송 사정으로 인해 롯데택배 이외 타 택배로 출고될 수 있으며,
운송수단에 따라 배송비는 선불 또는 착불(고객 부담) 발생될 수 있습니다.</p>
<br></br>

<span>퀵 / 고속 / KTX / 방문수령</span>
                <p>결제시 배송 방법 변경 (네이버페이는 장바구니에서 변경하셔야 적용 됩니다.)</p>
                <p>퀵 비용은 구매자 부담(착불)으로 서울,인천,경기 일부지역에서만 가능한 서비스입니다.</p>
                <p>고속버스 : 일반 고속버스 탁송료 20,000원 / KTX : 탁송료 30,000원
구매자 부담이며 지역 및 이동 시간에 따라 오후 3시 이후 접수 건은 당일 탁송 어려울 수 있습니다.</p>
                <p>제품은 [본사-인천]에서 준비되며, 방문 수령 장소는 [본사-인천]과 [구로매장]에서 선택 가능합니다.
제품이 준비되고 별도 연락 받으신 이후 수령 가능하며,결제 직후 수령은 불가합니다.</p>
                <p>상품 준비기간은 상품에 명시된 평균 준비기간에 따릅니다.</p>
<p>업체 직배송 및 해외 구매대행 상품의 경우 본사에서 준비되지 않는 상품으로 수령방법 변경 불가합니다.</p>
<br></br>
<span>배송안내</span>
                <p>평균 준비기간은 발송일정이 아니며 상품을 준비하는 기간을 평균적으로 명시하며, 제조사 입고처 사정에 따라 평균 준비기간은 변동됩니다.</p>
                <p>영업일 기준 오후 3시 이전 결제확인 된 주문 건은 당사 보유 재고 상품에 한하여 당일 출고 됩니다.</p>
                <p>1가지 이상 상품을 주문을 하신 경우 평균 준비기간이 가장 긴 상품의 준비기간에 맞춰 일괄 또는 나눔 발송 처리됩니다.</p>
                <p>일부 상품의 경우 재고 보유 상황에 따라서 평균 발송일에 다소 차이가 발생될 수 있습니다.
사전에 재고 확인 후 주문한 경우라도 이미 재고 소진이 된 이후에는 상품 준비기간은 안내 받으신 날짜와 상관없이 변경됩니다.</p>
               
            </div>
        </div>
        <div className="flex j pro_d_wrap">
            <div className="pro_d_h">반품/교환/취소 및
AS안내</div>
            <div className="pro_d_b">
                <span>취소 진행 안내</span>
                <p>직접 취소 가능 - 주문접수[결제 전-입금 전]상태에서는 회원,비회원 구분없이 디바이스마트 로그인 후 - 주문내역에서 '주문무효' 처리를 통해 취소 가능합니다.</p>
                <p>취소 접수 요청 - 결제완료 이후의 상태에서는 고객 본인 취소가 불가한 상태로 반드시 디바이스마트 고객센터 또는 1:1문의 게시판 통해 취소 문의 부탁드립니다.</p>
                <br></br>
                <span>반품/교환/취소 및 AS안내</span>
                <p>제품 수령 후 7일(주말/공휴일 포함) 이내 다음 사항의 경우 반품/교환 가능합니다.</p>
                <p>A/S 관련 문의는 업체마다 상이할 수 있으며, 해외 상품은 A/S 불가할 수 있습니다.</p>
                <p>초기 불량, 오배송이 판매자 귀책일 경우 왕복 택배비용은 판매자 부담입니다.</p>
                <p>모든 제품은 제조사, 공급 업체 사정 및 천재지변 등에 따라 품절/단종/대체 등 재고 소진 또는 단가 변동이 발생될 수 있으며,
단가 변동으로 인해 발생된 추가금은 고객부담으로 진행될 수 있는 점 양해 부탁드립니다.</p>
          
<br></br>
<span>반품/교환/취소 및 AS 가능 [O]</span>
                <p>수령한 제품의 초기 불량, 파손, 오배송,상세페이지 내용 불일치 등으로 사용이 불가한 경우</p>
                <p>단순 고객변심으로 인한 접수 기간이 7일을 초과하지 않고 , 제품을 훼손 또는 파손 하지 않고 , 미개봉/미사용인 경우</p>
                <p>제품 초기 불량으로 의심 되어 수령일 기준 7일이내 고객센터 접수 후 불량 확인 되었을 경우</p>
                <br></br>
<span className="red">반품/교환/취소 및 AS 불가 [X]</span>
                <p>제품 수령일 기준 7일(주말/공휴일 포함)이 초과한 경우</p>
                <p>제품 및 포장을 파손, 훼손 변형 시켜 재화의 가치를 하락시키거나 사용자 과실로 인해 불량이 발생한 경우</p>
                <p>사전 안내나 확인 없이 반품 또는 제품을 일방적으로 전달하여 당사로 보내는 경우</p>
                <p>주문 제작 상품, 절단 상품, 해외 배송 상품, 복사 가능 상품(서적,USB,소프트웨어,메일 전송 등), 일회성 상품의 경우에는 반품/교환/취소 /AS 절대 불가</p>
                <p>제품 수령 후 7일 또는 30일을 초과하여 오배송/누락 배송/택배사 분실로 교환/반품/취소 또는 추가 배송을 요청하는 경우</p>
                <p>해외 수입 상품을 1개 이상 주문 후 일부 상품의 단가 변동 및 품절/단종/대체 등 재고 소진 등의 이유로 공급이 불가하게 되어 나머지 상품 취소/반품/ 교환등을 요청의 경우</p>
                <p>1회 이상의 반품/교환/취소를 진행하였으나 동일 내용을 지속적으로 2회 이상 접수하여 수령일 기준 30일을 초과하는 경우</p>
                <p>고객 동의 후 대체/ 교환/AS 등을 진행하였으나 제품에 대한 인지 부족 또는 고객 변심으로 취소요청하는 경우 일부 상품의 경우 반품 /교환/취소 및 AS불가</p>
                <p>오배송 또는 AS 요청 교환 기간이 초과 또는 사용 후 반품을 보내는 경우</p>
               
            </div>
        </div>
        <div className="flex j pro_d_wrap">
            <div className="pro_d_h">주의사항</div>
            <div className="pro_d_b">
                <span className="red">반품/교환/ 취소 및 AS 포장 취급 주의사항을 반드시 확인하세요!</span>
                <p>자체 박스, 제품 훼손, 파손 등 본 제품 또는 본체 박스를 직접 훼손, 구성품 분실 등
수령한 상품과 다르게 반품 처리 된 경우에는 반품/교환/취소(환불) 및 AS진행 불가합니다.</p>
                <p>반품 접수 이후 3일 이내 택배기사 미방문시 또는 수거 처리가 안되는 경우, 착불 반송 진행이 안되는 경우 등 수령일 기준 7일을 초과하는 경우,
반드시 고객센터 또는 게시판으로 추가 요청 및 문의 주셔야 반품/교환/취소(환불) 및 AS 진행 가능하며, 수령일 7일 기간 초과시(주말/공휴일 포함) 반품 불가합니다.</p>
                <br></br>
                <span>올바른 반품 포장</span>
                <p>본체 박스, 제품 자체 정품 브랜드 박스에 송장을 부착하거나 테이핑하여 박스 및 본체 또는 구성품을 훼손/파손/분실한 경우 반품/교환/취소(환불)이 불가합니다.</p>
                <p>제품 자체 포장 박스 없이 제품과 반품하셨을 경우에 교환/환불 불가합니다.</p>
                <p>제품 불량, 오배송 된 제품들의 경우, AS요청시 반드시 정품 박스와 같이 받아보셨던 본체+구성품을 유지하여 수령 상태 그대로 보내주셔야 합니다.
구성품 누락의 경우 추가 배송비는 또는 왕복 배송비 모두 고객 부담입니다.</p>
<p>소비자 포장 취급주의로 인하여 제품이 파손 / 훼손되어 당사로 입고되는 경우 고객변심 또는 반품/교환/취소 및 AS 처리가 불가할 수 있습니다.</p>
          
<br></br>
<span>잘못된 반품 포장(교환/환불 불가)</span>
                <p>수령한 제품의 초기 불량, 파손, 오배송,상세페이지 내용 불일치 등으로 사용이 불가한 경우</p>
                <p>단순 고객변심으로 인한 접수 기간이 7일을 초과하지 않고 , 제품을 훼손 또는 파손 하지 않고 , 미개봉/미사용인 경우</p>
                <p>제품 초기 불량으로 의심 되어 수령일 기준 7일이내 고객센터 접수 후 불량 확인 되었을 경우</p>
               
            </div>
        </div>
       </div>
    )
}
export default Productdelivery