
import React from 'react'
import { Crumb1, Crumb2 } from '../components_btn/Breadcrums';
import { useNavigate, useLocation } from 'react-router-dom';
import Sortnavi from '../shop/shop_sort/Sortnavi';
import Pagetitle from '../components_btn/Pagetitle';
import { useEffect } from 'react';
import { usePopup } from '../../PopupContext';
function Iot({ cate, setBack, back }) {
  const { setShowPopup } = usePopup();
  const location = useLocation()
  const pathnum1 = location.pathname.split('/')[1]
  const pathnum2 = location.pathname.split('/')[2]
  useEffect(() => {
    setBack(true);
    setShowPopup(true); // 페이지가 변경될 때마다 팝업을 보이게 합니다.
  }, [setShowPopup, pathnum1])

  return (
    <div className="main shop displaybox">

      <div className='shop_div'>
        <div className='shop_sort'>
          <Sortnavi cate={cate} pathnum={pathnum1} />
        </div>

        <div className='shop_content'>
          {pathnum2 ?
            <Crumb2 num1={pathnum1} num2={pathnum2} />
            :
            <Crumb1 num={pathnum1} />}
          <Pagetitle value={'IOT서비스'} svalue={'최신 펌웨어, 도면, SDK등의 자료들을 다운받으세요'} />

        </div>

      </div>
    </div>
  );
}

export default Iot;
