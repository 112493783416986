import React from 'react'
import {HourglassOutlined} from '@ant-design/icons';
const Waitpage = () => {
  return (

    <div className='pcenter'>
        <div className='waitpgicon'><HourglassOutlined /></div>
        <div>준비중입니다</div>
    </div>
  )
};
export default Waitpage;