import axios from "axios";


export const allurl = 'https://needsrobot.com'
//export const allurl =  'http://localhost:5000'

const instance = axios.create({
  //baseURL: "https://needsrobot.com"
  baseURL: allurl
});
const fetchData = async (url, params) => {
  try {
    const response = await instance.get(url, {
      params,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (err) {
    console.log(`오류: ${err}`);
    return err;
  }
};
const dData = async (carriers, num) => {
  try {
    const response = await instance.get(`https://apis.tracker.delivery/carriers/${carriers}/tracks/${num}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (err) {
    console.log(`오류: ${err}`);
    return err;
  }
};
export { fetchData, dData };