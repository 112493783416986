import { useEffect, useState } from "react";
import { getcompare } from "../../service/function";
import { Table, Image, Modal, Button } from "antd";
import { Link } from "react-router-dom";
import React from 'react';
import './Comparebox.css';

import { DeleteOutlined } from '@ant-design/icons';

const Comparebox = ({ lastCheck, cate }) => {
  const [comparelist, setComparelist] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    const selectedObject = lastCheck.map((item) => item.id);
    getcompare(selectedObject).then(function (response) {
      setComparelist(response.data);
    });
  }, [lastCheck]);

  const showModal = (id) => {
    setDeleteId(id);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const newData = comparelist.filter((item) => item.id !== deleteId);
    setComparelist(newData);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: '이미지',
      align: 'center',
      dataIndex: 'img1',
      width: '60px',
      render: (record) => <Image src={record} alt="product" className="table-image" />,
    },
    {
      title: '카테고리',
      align: 'center',
      render: (record) => {
        const cate1 = cate.find((c) => c.subcatenum === record.subcatenum);
        if (!cate1) {
          return null;
        }
        return (
          <div >
            {cate1.category}
          </div>
        );
      },
      width: '30px',
      className: 'table-column',
    },
    {
      title: '품목',
      render: (record) => {
        const cate1 = cate.find((c) => c.subcatenum === record.subcatenum);
        if (!cate1) {
          return null;
        }
        return (
          <div >
            {cate1.subcategory}
          </div>
        );
      },
      width: '20px',
      align: 'center',
      className: 'table-column',
    },
    {
      title: '이름',
      dataIndex: 'pName',
      align: 'center',
      width: '50px',
      className: 'table-column',
      ellipsis: true,
    },
    {
      title: '브랜드',
      align: 'center',
      dataIndex: 'brand',
      width: '20px',
      className: 'table-column',
    },
    {
      title: 'Inch',
      dataIndex: 'inch',
      width: '20px',
      align: 'center',
      className: 'table-column',
    },
    {
      title: 'Material',
      dataIndex: 'material',
      width: '20px',
      align: 'center',
      className: 'table-column',
    },
    {
      title: 'Color',
      dataIndex: 'color',
      width: '20px',
      align: 'center',
      className: 'table-column',
    },
    {
      title: '가격',
      dataIndex: 'pPrice',
      align: 'center',
      width: '10%',
      className: 'table-column',
      render: (price) => <span style={{ color: '#1890ff' }}>{price.toLocaleString()}원</span>,
    },
    {
      title: '',
      align: 'center',
      width: '20px',
      render: (_, record) =>
        comparelist.length >= 1 ? (
          <Button type="danger" onClick={() => showModal(record.id)}><DeleteOutlined /></Button>
        ) : null,
    },
    {
      title: '',
      width: '20px',
      render: (record) => {
        const cate1 = cate.find((c) => c.subcatenum === record.subcatenum);
        if (!cate1) {
          return null;
        }
        return (
          <Link to={`/${cate1.category}/${cate1.subcategory}/${record.id}`}>
            링크
          </Link>
        );
      },
    },
  ];

  return (
    <div className="comparewrap">
      <Table
        rowKey="id"
        columns={columns}
        dataSource={comparelist && comparelist}
        pagination={false} // Disable pagination for better comparison
        bordered // Add border to the table
        className="compare-table"
        scroll={{
          y: 500,
        }}
      />
      <Modal
        title="Confirm Deletion"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
        className="delete-modal"
      >
        <p>삭제 하시겠습니까?</p>
      </Modal>
    </div>
  );
};

export default Comparebox;
