import React from 'react'
import { Carousel } from 'antd';
import ban1 from '../../Image/ban1.png'
import ban2 from '../../Image/ban2.jpg'
import ban3 from '../../Image/ban3.jpg'
import ban4 from '../../Image/ban4.png'

function Mainslide() {

  return (
    <Carousel autoplay effect='fade'>
      <div>
        <div className='slidebox'>
          <img style={{ width: '100%', height: '100%' }} src='https://ifh.cc/g/QXMN8m.jpg' />
        </div>
      </div>
      <div>
        <div className='slidebox'>
          <img style={{ width: '100%', height: '100%' }} src='https://ifh.cc/g/dYzVDs.jpg' />
        </div>
      </div>
      <div>
        <div className='slidebox'>
          <img style={{ width: '100%', height: '100%' }} src='https://ifh.cc/g/25J76V.jpg' />
        </div>
      </div>
      <div>
        <div className='slidebox'>
          <img style={{ width: '100%', height: '100%' }} src='https://ifh.cc/g/jBR08Q.png' />
        </div>
      </div>
    </Carousel>
  );
}

export default Mainslide;
