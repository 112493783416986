import React, { useState, useEffect } from "react";
import Approuter from "./Approuter";
import './style.css';
import './reset.css';
import { getCate, getMainCate, getItemWithExpireTime } from "./service/function";
import { PopupProvider, usePopup } from './PopupContext';

function App() {
  const [init, setInit] = useState(false);
  const [cates, setCates] = useState([]);
  const [userInfo, setUserInfo] = useState(JSON.parse(getItemWithExpireTime('userInfo')));
  const [token, setToken] = useState(getItemWithExpireTime('token'));
  const [cate, setCate] = useState([]);

  return (
    <PopupProvider>
      <MainApp
        init={init}
        setInit={setInit}
        setCate={setCate}
        cates={cates}
        setCates={setCates}
        setToken={setToken}
        cate={cate}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        isLoggedIn={Boolean(token)}
      />
    </PopupProvider>
  );
}

const MainApp = ({ init, setInit, setCate, cates, setCates, setToken, cate, userInfo, setUserInfo, isLoggedIn }) => {
  const { showPopup, setShowPopup, dontShowAgain, setDontShowAgain } = usePopup();

  useEffect(() => {
    const lastClosed = localStorage.getItem('popupClosedDate');
    const isDontShowAgain = localStorage.getItem('dontShowAgain') === 'true';

    if (!lastClosed || (new Date().getTime() - new Date(lastClosed).getTime()) > 7 * 24 * 60 * 60 * 1000) {
      setDontShowAgain(isDontShowAgain);
      if (!isDontShowAgain) {
        setShowPopup(true);
      }
    }

    getCate().then(response => {
      setCate(response.data);
    });

    getMainCate().then(response => {
      setCates(response.data);
    });

    setInit(true);
  }, [setInit, setCate, setCates, setToken, setDontShowAgain, setShowPopup]);

  const closePopup = () => {
    setShowPopup(false);
    if (dontShowAgain) {
      localStorage.setItem('popupClosedDate', new Date().toISOString());
      localStorage.setItem('dontShowAgain', 'true');
    }
  };

  const handleCheckboxChange = (event) => {
    setDontShowAgain(event.target.checked);
  };

  return (
    <>
      {init ? (
        <>
          <Approuter setCate={setCate} cates={cates} setCates={setCates} setToken={setToken} cate={cate} userInfo={userInfo} setUserInfo={setUserInfo} isLoggedIn={isLoggedIn} />
          {showPopup && (
            <div className="popup-overlay">
              <div className="popup-content">
                <button className="popup-close" onClick={closePopup}>×</button>
                <h2>쇼핑몰 준비 중입니다.</h2>
                <p>쇼핑몰을 작업중입니다 현재 내용들은 테스트용 제품들이니 조금만 기다려주세요</p>
                <div className="popup-checkbox">
                  <input type="checkbox" id="dont-show-again" checked={dontShowAgain} onChange={handleCheckboxChange} />
                  <label htmlFor="dont-show-again">다시 보지 않음</label>
                </div>
                <button onClick={closePopup}>닫기</button>
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default App;
