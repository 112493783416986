
import React from 'react'
import Userdata from "./Userdata";

function Userlist() {
    return (
      <div className="Writeproduct main">
          <Userdata/>
      </div>
    );
  }
  
  export default Userlist;
  