import React from 'react'
import Main from "../../Main/Main";


function Maincontrol({ cate,cates,setCates,setCate }) {

  return (
<div className="maincontrol">
<Main edit={true}/>
  
</div>
  )
}

export default Maincontrol;